@font-face {
  font-family: "Courgette";   /*Can be any text*/
  src: local("Courgette-Regular"),
    url("./fonts/Courgette-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

form {
  position: absolute;
    top: 10%;
    left: 81%;
    display: flex;
    width: 20%;
    flex-direction: column;
    /* background-color: #222; */
    color: white;

}

.container {
  /* display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%; */
  position: relative;
  width: 100%;
  margin: auto;
}

#root {
  min-height: 100vh;
  width: 100%;
  background-color: #222;
}

.phone {
  z-index: 6;
  position: absolute;
  top: 18%;
  /* padding-top: 10%; */
  left: 5%;
  width: 8%;
  caret-color: transparent;


  height: 7%;
  /* background-color: yellow; */
}

.image-partition {
  position: absolute;
  bottom: 0;
  left: 100%;
  width: 900%; 
  
}

.absolute-container {
  position: absolute;
  top: 0;
  left: 0%;
  z-index: 10;
}

.image-map {
  position: relative;
  z-index: 10;
}

.map-button {
  width: 1.5vw;
  height: 1.5vw;
  background-image: url('./assets/Button1.png');
  z-index: 99;
  position: absolute;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
  caret-color: transparent;
  /* animation: fadeOut ease 0.5s; */

}

.map-1 {
  top: 18.4%;
  left: 20.7%;
}

.map-2 {
  top: 10.8%;
  left: 63.2%;
}
.map-3 {
  top: 36.8%;
  left: 27.4%;
}
.map-4 {
  top: 37.5%;
  left: 56.5%;
}
.map-5 {
  top: 48.7%;
  left: 46%;
}
.map-6 {
  top: 43.6%;
  left: 79.5%;
}
.map-7 {
  top: 60.2%;  
  left: 5.8%;
}
.map-8 {
  top: 62.8%;
  left: 36.5%;
}
.map-9 {
  top: 71.4%;
  left: 61.7%;
}
.map-10 {
  top: 83.7%;
  left: 23.4%;
}
.map-11 {
  top: 80.9%;
  left: 85.2%;
}

.map-12 {
  top: 12.2%;
  left: 4.2%;
}
.map-13 {
  top: 16%;
  left: 41%;
}
.map-14 {
  top: 35.4%;
  left: 88.5%;
}
.map-15 {
  top: 51.5%;
  left: 65%;
}
.map-16 {
  top: 60%;
  left: 75%;
}

.activeButton {
  background-image: url('./assets/Button2.png');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  animation: fadeIn ease 0.5s;
  z-index: 100;
}

/* .inactiveButton {
  background-image: url('./assets/Button2.png');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  animation: fadeOut ease 0.5s;
} */
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
/* @keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
} */

.streetName {
  position: absolute;
  top: 51%;
  left: 52%;
  z-index: 999999;
  text-align: center;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}

.streetNameSpan {
  display: inline-block;
  opacity: 0;
  font-family:'Courgette', cursive;
  font-size: 5em;
  color: white;
}

.streetNameSpan.active {
  animation: fade-in 2s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.image-fouille {
  width: auto;
  height: 100vh;
}

.close-map {
  position: absolute;
    left: 97%;
    top: 1%;
  
    font-size: 2em;
    font-weight: bold;
    cursor: pointer;
    color: white;
    z-index: 9999;
    caret-color: transparent;
}

.close {
  position: absolute;
    left: 965%;
    /* width: 900%; */
    bottom: 1035%;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
    caret-color: transparent;
}

@media (max-width: 650px) { 
  .close {
    font-size: 20px;
    left: 955%;
    /* width: 900%; */
    bottom: 1012%;
  }
}

.USB {
  width: 5%;
  height: 5%;
  display: block;
  position: absolute;
  top: 45%;
  left: 45%;
  cursor: default;
  caret-color: transparent;
  /* background-color: red; */
}



.mobile-container {
  margin: auto;
  color: white;
  padding: 0 10px;
  text-align: center;
  height: 100vh;
  display: flex;
  text-align: center;
  align-content: center;
  align-items: center;
}

.partition {
  caret-color: transparent;
  z-index: 6;
  position: absolute;
  top: 65%;
  /* padding-top: 10%; */
  left: 3%;
  width: 9%;
  height: 6%;
  /* background-color: green; */
}

.map {
  width: 10%;
  height: 13%;
  display: block;
  position: absolute;
  top: 0%;
  left: 18%;
  cursor: default;
  caret-color: transparent;
  /* background-color: yellow; */
}

.audioPlayer {
  margin-left: 115%;
  margin-top: 20%;
}

.symbols-area {
  text-align: center;
  width: 90%;
  /* margin: auto; */
  display: flex;
  /* align-self: flex-end; */
  justify-content: center;
  margin-bottom: 25%;
}

.symbol {
  width: 0.8vw;;
  margin: 0 0.2vw;
}
.symbol1, .symbol5, .symbol7, .symbol8, .symbol9 {
  transform: rotate(-90deg);
}





.symbol-group {
  margin: 0 0.4vw;
}

.page-container {
  height: 100vh;;
  width: 100%;
  margin: auto;
  height: 100%;
  text-align: center;
  /* background-image: url('../src/assets/background.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
}

.page-container-home {
  height: 100%;;
  width: 100%;
  }

.fouille-relative-container{
  display: inline-block;
  position: relative;
  /* width: 100%; */
  text-align: center;
  margin: auto;
}
.fouille-container {
  /* background-image: url('../public/fouille.jpg'); */
  /* background-image: url('../public/scroll.svg'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100%;
  width: 100%;
}

.welcomeMessageBackground {
  background-image: url('./assets/Scroll.png');
  display: inline-block;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  top: 5%;
  left: 3%;
  height: 50%;
  width: 18%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.digits-squares {
  display: flex;
  margin: auto;
  margin-top: 2%;
  width: 70%;
  justify-content: center;
  gap: 0.3vw;
}

.square {
  border: solid 2px white;
  /* background: #afafaf; */
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.doorPlate {
  width: 2.6%;
    height: 2.8%;
    position: absolute;
    border-radius: 2.9px;
    top: 55.7%;
    left: 70.1%;
    caret-color: transparent;
    transition: box-shadow 2s ease;
}



.doorPlateImage {
  background-image: url('./assets/plate.png');
  display: inline-block;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  top: 25%;
    left: 30%;
    height: 50%;
    width: 40%;
  display: none;
}



.doorPlate.active {
  /* box-shadow: 0 0 5px 3px #cbd3c8, 0 0 10px 5px #9eab8d, 0 0 15px 10px #97a78e, 0 0 20px 15px #cba79f; */
  box-shadow: 0 0 2px 1px rgb(203 211 200 / 0.1), 0 0 4px 2px rgb(158 171 141 / 0.1), 0 0 6px 3px rgb(151 167 142 / 0.1), 0 0 8px 4px rgb(203 167 159 / 0.1);
  
  /* box-shadow: 0 0 2px 1px #cbd3c8, 0 0 4px 2px #9eab8d, 0 0 8px 4px #97a78e, 0 0 10px 7px #cba79f;   */
}

.doorPlate:hover {
  /* box-shadow: 0 0 5px 3px #cbd3c8, 0 0 10px 5px #9eab8d, 0 0 15px 10px #97a78e, 0 0 20px 15px #cba79f; */
  /* box-shadow: 0 0 2px 1px rgb(203 211 200 / 0.1), 0 0 4px 2px rgb(158 171 141 / 0.1), 0 0 6px 3px rgb(151 167 142 / 0.1), 0 0 8px 4px rgb(203 167 159 / 0.1); */
  
  box-shadow: 0 0 2px 1px rgb(203 211 200 / 0.5), 0 0 4px 2px rgb(158 171 141 / 0.5), 0 0 8px 4px rgb(151 167 142 / 0.5), 0 0 10px 7px rgb(203 167 159 / 0.5);
}

.doorPlate:hover + .doorPlateImage {
  display: block;
 }



.welcomeMessageText {
  display: flex;
  justify-content: center;
  /* margin: auto; */
  margin-top: 20%;
  /* align-self: center; */
  width: 80%;
  /* flex-basis: 22%; */
  font-size: 1.2vw;
  text-align: center;
  font-weight: 100;
  font-family: 'Courgette', cursive;
}

@media (max-width: 700px) { 
  .welcomeMessageBackground {
    padding: 55px 0;
    height: 190px;
    display: flex;
    align-items: center;
    margin-top: 0%;
  }
  .welcomeMessageText {
    display: flex;
    flex-basis: 60%;
    font-size: 16px;
    text-align: center;
  }
}

@media (max-width: 1050px) { 
  .welcomeMessageText {
    display: flex;
    flex-basis: 35%;
    text-align: center;
  }
}

.error-message {
  text-align: center;
    color: black;
    font-size: large;
    font-weight: 600;
    background: #dad5cf;
    border-radius: 6px;
    margin-top: 5px;
    width: 50%;
    margin: auto;
    margin-top: 2%;
}
.numPad-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  gap: 1vw;
  padding: 3% 0 0px 0;
}

.numPad {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.numPad-row {
  display: flex;
  flex-direction: row;
}

.numPad-cell {
  /* flex-basis: 33%;
  width: 33%; */
}

.numPad-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  caret-color: transparent;
}

.numPad-header-number {
  margin: 0 10px;
  color: #f79f56;
  font-size: x-large;
  font-weight: 600;
}

.numPad .numPad-body > .numPad-row div {
  width: 2.5vw;
  height: 2.5vw;
  margin: 0.5vw;
  /* border: 2px solid #FF5722; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15%;
  cursor: pointer;
  color: black;
    font-size: 1.5vw;
    font-weight: 600;
    background-image: url('./assets/keyCode.jpeg');
    background-position: center;
    background-size: cover;
  /* background: linear-gradient(152deg, rgba(223,116,72,1) 23%, rgba(253,169,89,1) 56%, rgba(253,169,89,1) 50%, rgba(253,169,89,1) 100%); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.messagerie-container {
  display: flex;
  height: 100%;
}

.sidebar {
  width: 250px;
  background-color: #fff;
  border-right: 1px solid #ddd;
  padding: 10px;
}

.main {
  flex: 1;
  padding: 10px;
}

.compose-btn {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 10px;
}

.inbox-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.inbox-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.inbox-item.active {
  background-color: #eee;
}

.inbox-item-count {
  background-color: #ddd;
  color: #555;
  font-size: 14px;
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: auto;
}

.toolbar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.back-btn {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.message-details {
  margin-left: 10px;
}

.message-details-title {
  font-weight: bold;
  font-size: 20px;
}

.message-details-sender {
  font-size: 14px;
}

.message-body {
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.5;
}


